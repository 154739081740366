
/** npm start */

import React from 'react'
import Countdown, { zeroPad } from "react-countdown";
import ImgAudVideo from '../separate/ImgAudVideo';
import config from "../views/config/config";
/** local files import */

import auctionOne from '../assets/evolvex/images/auction1.png'
import { Link } from 'react-router-dom';

const AuctionCard = (props) => {
console.log("andajsndjaskdasd",props);
    const { NFTOrginalImageIpfs, CollectionNetwork, ContractAddress, NFTOwner, NFTId,NFTCreator,EndClockTime,OriginalFile,CompressedThumbFile ,CompressedFile} = props.promotedToken
    const renderer = ({ days, hours, minutes, seconds }) => {
        return (
            <div className={`countdownRenderer d-flex justify-content-center align-items-center`}>
                <p >{days < 10 ? `0${days}` : days}</p>
                <p>D</p> <p>:</p>
                <p >{hours < 10 ? `0${hours}` : hours}</p>
                <p>H</p> <p>:</p>
                <p >{minutes < 10 ? `0${minutes}` : minutes}</p>
                <p>M</p> <p>:</p>
                <p >{seconds < 10 ? `0${seconds}` : seconds}</p>
                <p>s</p>
            </div>
        )
    }


    return (
        <div className='ev-auctioncard'>
                <Link
                    to={`/info/${CollectionNetwork}/${ContractAddress}/${NFTOwner}/${NFTId}`}
                            >
                                <>
            <div className='ev-auctioncard__profile'>
            <ImgAudVideo
                                file={`${config.IMG_URL}/nft/${NFTCreator}/Compressed/NFT/${CompressedFile}`}
                                // origFile={`${config.IMG_URL}/nft/${NFTCreator}/Original/NFT/${OriginalFile}`}
                                origFile={config.IPFS + NFTOrginalImageIpfs}
                                thumb={`${config.IMG_URL}/nft/${NFTCreator}/Compressed/NFT_THUMB/${CompressedThumbFile}`}
                                type={ 
                                    CompressedFile
                                    ? CompressedFile?.includes(
                                        ".webp"
                                      )
                                      ? "image"
                                      : CompressedFile.includes(
                                          ".webm"
                                        )
                                      ? "video"
                                      : "audio"
                                    : CompressedFile
                                }
                              />
                <img src={auctionOne} alt='auction' />
            </div>
            <div className='ev-auction__content'>
                <h5 >Auction Ends In</h5>
                <Countdown
                                    date={
                                      EndClockTime
                                        ? EndClockTime
                                        : Date.now() + 8000000
                                    }
                                    autoStart={true}
                                    renderer={renderer}
                                  />
                                  
                {/* <Countdown date={Date.now() + 245006600} renderer={renderer} /> */}
            </div>
            </>
            </Link>
        </div>
    )
}

export default AuctionCard