import React, { useEffect, useState } from "react";
import Wallet1 from "../assets/images/phantom.png";
import Wallet2 from "../assets/images/svg/icon-wallet-2.svg";
import Wallet3 from "../assets/images/walletconnect.png";
import Wallet4 from "../assets/images/TWT.png";
import Wallet5 from "../assets/images/coinbase.png";

import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { NavLink } from "react-router-dom";
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Account_Section } from '../redux/constants'

import { connectWallet } from './hooks/useWallet';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isEmpty } from '../actions/common';
import { useNavigate, useParams } from "react-router-dom";

import { userRegister, GetUserCookieToken } from '../actions/axioss/user.axios'
import { GetNftCookieToken } from "../actions/axioss/nft.axios";
import { useWeb3React } from '@web3-react/core';
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import EditProfile from "./EditProfile";

// import { WalletConnectConnector } from "@web3-react/walletlink-connector";
// import { WalletLinkConnector,WalletConnectConnector } from "@web3-react/walletlink-connector";
import { Web3ReactProvider } from '@web3-react/core'
import Config from "./config/config"
import { Web3Provider } from "@ethersproject/providers";
import Web3 from "web3";
import BeforeloginEditProfile from "./BeforeloginEditProfile";
import EvFooter from "../separate/EvFooter.js";

export default function ConnectWallet() {

    const { userdata } = useSelector(state => state.LoginReducer)
    const { Bio, CustomUrl, DisplayName, EmailId, Facebook, Instagram, Twitter, Youtube } = userdata
    const [wallet, setWallet] = React.useState([
        { icon: Wallet1, beta: "Alpha", title: "Phantom", description: "Phantom Wallet is a non-custodial cryptocurrency wallet and browser extension that provides users a secure and user-friendly way to manage their digital assets. Initially made for the Solana blockchain, Phantom now supports Ethereum, Polygon and Bitcoin, but remains one of the most popular wallets for Solana.", id: "phantom" },
        { icon: Wallet5, beta: "Alpha", title: "Coinbase", description: "Coinbase Wallet is a self-custody wallet that gives you complete control of your crypto This means that the private keys (that represent ownership of the cryptocurrency) for your Wallet are stored directly on your mobile device and not with a centralized exchange like Coinbase.com", id: "coinbase" },
        // { icon: Wallet4, beta: "Alpha", title: "Trust Wallet", description: "Trust Wallet is a multi-chain, self-custodial wallet supporting numerous assets and blockchains, allowing users full control over their funds and easy access to DApps on iOS and Android.", id: "TrustWallet" },
    ])
    const [mobileWallet, setMobileWallet] = React.useState([
        { icon: Wallet1, beta: "Alpha", title: "phantom", description: "Phantom Wallet is a non-custodial cryptocurrency wallet and browser extension that provides users a secure and user-friendly way to manage their digital assets. Initially made for the Solana blockchain, Phantom now supports Ethereum, Polygon and Bitcoin, but remains one of the most popular wallets for Solana.", id: "phantom" },
        { icon: Wallet3, beta: "Alpha", title: "Coinbase", description: "Coinbase Wallet is a self-custody wallet that gives you complete control of your crypto This means that the private keys (that represent ownership of the cryptocurrency) for your Wallet are stored directly on your mobile device and not with a centralized exchange like Coinbase.com.", id: "coinbase" },
    ])

    const { referralLink } = useParams();
    const [updatePage, setUpdatePage] = useState(true)
    const [dataa, setDataa] = useState({})
    const [accDetail, setAccDetail] = useState({})

    const dispatch = useDispatch();
    var navigate = useNavigate();
    const { token } = useSelector(state => state.LoginReducer.User)
    const { activate, deactivate, active, chainId, account } = useWeb3React();

    const initialConnectWallet = async (type, title) => {
        console.log("vvaaaav", window.ethereum, window.web3,type)
        const id = toast.loading(type + "Connecting")
        var accountDetails = await connectWallet(type)
        console.log("11111", accountDetails, type, window.ethereum)
        if (!isEmpty(accountDetails)) {
            var resp = await setWalletAddress('InitialConnect', accountDetails?.accountAddress, type, id)
            console.log("resp", resp)
            setAccDetail(accountDetails)
            if (resp?.success === 'success') {
                toast.update(id, { render: resp.msg, type: resp.success, autoClose: 1000, isLoading: false, closeButton: true, closeOnClick: true })
                dispatch({
                    type: "Account_Section",
                    Account_Section: { AccountDetails: accountDetails }
                })
                navigate("/")
            }


        }
        else toast.update(id, { render: `${type} wallet not recognised`,  type: 'error', autoClose: 1000, isLoading: false, closeButton: true, closeOnClick: true })
    }
    const setWalletAddress = async (type, walletAddress, walletType, id) => {
        console.log("referralLink", referralLink);
        if (walletAddress) {
            var NewMethod = {
                Type: type,
                WalletAddress: walletAddress,
                WalletType: walletType,
            };
            if (referralLink) {
                NewMethod.Bio = Bio
                NewMethod.Facebook = Facebook
                NewMethod.Instagram = Instagram
                NewMethod.DisplayName = DisplayName
                NewMethod.CustomUrl = CustomUrl
                NewMethod.Twitter = Twitter
                NewMethod.Youtube = Youtube
                NewMethod.EmailId = EmailId
                NewMethod.refer_code = referralLink
            }

            let Resp = await userRegister(NewMethod);
            if (Resp?.already && Resp?.data?.DisplayName != "") {
                if (Resp?.success == 'success') {
                    dispatch({
                        type: 'Register_Section',
                        Register_Section: {
                            User: {
                                payload: Resp.data,
                                token: Resp.token ? Resp.token : token
                            }
                        }
                    })
                    document.cookie = 'token' + "=" + Resp?.token + ";" + ";path=/";
                    GetNftCookieToken();
                    GetUserCookieToken();
                    return Resp
                }
                else return Resp
            } else {
                toast.update(id, { render: "Create Profile First", type: 'success', autoClose: 1000, isLoading: false, closeButton: true, closeOnClick: true })
                setDataa(Resp?.data)
                console.log("Resp?.dataResp?.data", Resp?.data);
                // setUpdatePage(false)
                navigate(`/createProfile/${walletAddress}`, { state: { code: referralLink ? referralLink : "", ...Resp?.data } })


            }
        }
        else return { success: 'error', msg: 'No Address Detected.. Check Your Wallet' }

    }


    useEffect(() => {
        if (referralLink) {
            // navigate(`/createProfile/${walletAddress}`,{ state: {code : referralLink ? referralLink : "" } })
            setUpdatePage(false)
        }
    }, [])

    return (
        <>
            {/* {(updatePage && !referralLink) && <Header />} */}
            <Header referralLink={referralLink} />
            {updatePage ?
                <div className="wallets">
                    {(updatePage && !referralLink) && <section className="tf-page-title" data-aos="fade-left">
                        {/* <div className="tf-container"> */}
                        <div className="container container-width">

                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="breadcrumbs">
                                        <li><NavLink to="/">Home <MdKeyboardArrowRight /></NavLink></li>
                                        <li>Connect Wallet</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>}
                    <section className="tf-connect-wallet" >
                        {/* <div className="tf-container"> */}
                        <div className="container container-width">

                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <div className="tf-heading style-5">
                                        <h4 className="heading" data-aos="fade-up">Connect Your Wallet</h4>
                                        <p className="sub-heading" data-aos="fade-down">Choose how you want to connect. There are several wallet providers.</p>
                                    </div>
                                </div>

                                {
                                    wallet.map((data, index) => {
                                        return (
                                            <div className="col-lg-4 col-md-6" data-aos="zoom-in">
                                                <div className="tf-wallet" onClick={() =>
                                                    initialConnectWallet(data.id)}>
                                                    <div className="icon">
                                                        <img src={data.icon} alt="Image" width="100" height="100" classNameName="img-fluid" />
                                                        <span><h6 className="title">{data.title}</h6></span>
                                                    </div>
                                                    <p className="content">{data.description}</p>
                                                </div>
                                            </div>

                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section>
                </div> :
                <>

                    <BeforeloginEditProfile valData={dataa} accountDetails={accDetail} refcode={referralLink} update={() => setUpdatePage(true)} />
                </>}

            {/* </Web3ReactProvider> */}
            {/* <Footer /> */}
            <EvFooter />
        </>
    )
}